import React from "react";
import { Grid, makeStyles, Container } from "@material-ui/core";
import LoginBanner from "./LoginBanner";
import LoginForm from "./LoginForm";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "30px",
    background: "linear-gradient(#fff 69%, #e9ecee 69%)"
  }
}));

const Login = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Container>
        <Grid container>
          <Grid item md={7}>
            <LoginBanner />
          </Grid>
          <Grid item md={4}>
            <LoginForm />
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Login;
