import React, { useState } from "react";
import { useRouter } from "next/router";
import { makeStyles, Typography } from "@material-ui/core";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import { Button, InputPassword, InputText } from "@loop-brasil/material";
import { useMutation } from "react-query";
import { signIn } from "next-auth/react";
import { postResendConfirmation } from "api";
import { event } from "gtm";
import { Keys } from "constants/Keys";

const useStyles = makeStyles({
  form: {
    backgroundColor: "#fff",
    border: "2px solid #E9ECEE",
    borderRadius: "8px",
    marginBottom: "40px"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "480px",
    padding: "32px 24px"
  },
  login: {
    position: "relative",
    height: "82px",
    "&::after": {
      content: "''",
      position: "absolute",
      backgroundColor: "#E9ECEE",
      top: "66%",
      left: "47%",
      width: "25px",
      height: "5px",
      borderRadius: "3px"
    }
  },
  link: {
    textAlign: "right",
    fontSize: "0.8rem",
    "& a": {
      color: "#2E323C"
    },
    "& a:hover": {
      color: "#1E212C"
    }
  }
});

const ResendMailLink = ({ onClick }) => {
  return (
    <Link href="/">
      <a
        style={{ cursor: "pointer", fontWeight: 600, display: "block" }}
        onClick={onClick}
      >
        Reenviar e-mail de confirmação
      </a>
    </Link>
  );
};

const LoginForm = () => {
  const classes = useStyles();
  const router = useRouter();
  const { next } = router.query;
  const [errorMessage, setErrorMessage] = useState("");
  const [showResendLink, setShowResendLink] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues
  } = useForm();

  const resendConfirmation = useMutation(
    Keys.postResendConfirmation(),
    postResendConfirmation,
    {
      onSuccess: () => {
        setShowResendLink(false);
        setErrorMessage("Enviamos o link de confirmação para seu e-mail");
        setTimeout(() => setErrorMessage(""), 3000);
      }
    }
  );

  const onSubmit = async data => {
    const username = data.email.trim();
    const password = data.password.trim();

    const res = await signIn("credentials-auth", {
      username,
      password,
      redirect: false
    });

    if (!res) return;

    if (res.ok) {
      event("login");
      router.push(next ?? "/");
    }

    if (res.error) {
      const { error, type } = JSON.parse(res.error);
      if (type === "unverified_email") {
        setShowResendLink(true);
      }
      setErrorMessage(error);
    }
  };

  const resendMail = e => {
    e.preventDefault();
    const email = getValues("email");
    resendConfirmation.mutate(email);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.box}>
        <div className={classes.login}>
          <Typography align="center" variant="h3">
            Login
          </Typography>
        </div>
        {errorMessage !== "" ? (
          <Alert severity="error" className={classes.alert}>
            {errorMessage}
            {showResendLink ? <ResendMailLink onClick={resendMail} /> : null}
          </Alert>
        ) : null}
        <InputText
          label="E-mail"
          className={classes.input}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register("email", {
            required: "Insira um e-mail válido"
          })}
        />
        <InputPassword
          label="Senha"
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          {...register("password", {
            required: "O campo senha não pode estar vazio"
          })}
        />
        <div className={classes.link}>
          <Link href="/esqueci-minha-senha">
            <a>Esqueceu sua senha?</a>
          </Link>
        </div>
        <Button type="submit" disableElevation>
          Entrar
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
