import React from "react";
import Head from "next/head";
import { Header } from "components";
import Login from "components/Login/Login";

const LoginPage = () => {
  return (
    <>
      <Head>
        <title>Login | Loop Veículos</title>
      </Head>
      <Header />
      <Login />
    </>
  );
};

export default LoginPage;
